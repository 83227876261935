document.querySelectorAll("[data-dn-toggle='show-more']").forEach(button => {
  const target = button.getAttribute('data-dn-target')
  const reveal = parseInt(button.getAttribute('data-dn-reveal'), 10)

  // Ensure we only show the first <reveal> elements
  document.querySelectorAll(`${target} > *`).forEach((element, index) => {
    if (index < reveal) {
      return
    }

    element.classList.add('d-none')
  })

  button.addEventListener('click', () => {
    let revealed = 0

    const getHiddenElements = () =>
      document.querySelectorAll(`${target} > .d-none`)

    getHiddenElements().forEach(element => {
      // Ensure we only show <reveal> new elements
      if (revealed === reveal) {
        return
      }

      element.classList.remove('d-none')
      revealed += 1
    })

    if (getHiddenElements().length === 0) {
      button.classList.add('d-none')
    }
  })
})
